import React, { useEffect } from 'react'
import './styles.css'

import CarouselImg1 from '../../imgs/prpoImgs/photo 1.jpg'
import CarouselImg2 from '../../imgs/prpoImgs/photo 2.jpg'
import CarouselImg3 from '../../imgs/prpoImgs/photo 3.jpg'
import CarouselImg4 from '../../imgs/prpoImgs/photo 4.jpg'
import CarouselImg5 from '../../imgs/prpoImgs/photo 5.jpg'
import CarouselImg6 from '../../imgs/prpoImgs/photo 6.jpg'
import CarouselImg7 from '../../imgs/prpoImgs/photo 7.jpg'
import CarouselImg8 from '../../imgs/prpoImgs/photo 8.jpg'
import CarouselImg9 from '../../imgs/prpoImgs/photo 9.jpg'
import CarouselImg10 from '../../imgs/prpoImgs/photo 10.jpg'
import CarouselImg11 from '../../imgs/prpoImgs/photo 11.jpg'
import CarouselImg12 from '../../imgs/prpoImgs/photo 12.jpg'
import CarouselImg13 from '../../imgs/prpoImgs/photo 13.jpg'
import CarouselImg14 from '../../imgs/prpoImgs/photo 14.jpg'
import CarouselImg15 from '../../imgs/prpoImgs/photo 15.jpg'
import CarouselImg17 from '../../imgs/prpoImgs/photo 17.jpg'
import CarouselImg18 from '../../imgs/prpoImgs/photo 18.jpg'
import CarouselImg19 from '../../imgs/prpoImgs/photo 19.jpg'
import CarouselImg20 from '../../imgs/prpoImgs/photo 20.jpg'
import CarouselImg21 from '../../imgs/prpoImgs/photo 21.jpg'
import CarouselImg22 from '../../imgs/prpoImgs/photo 22.jpg'

export default function Deck () {
  
  const cards = [
  CarouselImg8,
  CarouselImg1,
  CarouselImg2,
  CarouselImg7,
  CarouselImg3,
  CarouselImg4,
  CarouselImg5,
  CarouselImg11,
  CarouselImg6,
  CarouselImg9,
  CarouselImg10,
  CarouselImg12,
  CarouselImg13,
  CarouselImg15,
  CarouselImg14,
  CarouselImg17,
  CarouselImg18,
  CarouselImg19,
  CarouselImg20,
  CarouselImg21,
  CarouselImg22,
  ]
  
  useEffect( () => {
    var clicked = false
    document.getElementById('ddd').style.width = document.getElementById('ddMm').offsetWidth + 'px'
    document.getElementById('ddd2').style.width = document.getElementById('ddMm2').offsetWidth + 'px'

    window.addEventListener( 'scroll', function () {
      let thisComponent = document.querySelector( '#tesr' )
      var value = window.scrollY

      if ( thisComponent.offsetTop < value + window.innerHeight / 2 && value + window.innerHeight / 2 < thisComponent.offsetTop + thisComponent.offsetHeight && !clicked ) {
        clicked = true
        this.document.getElementById( 'footerContactUs' ).click()
      } 
    
    } )

    const carouselCont = document.querySelector( '.main-carousel-cont' )
    let mouseDown = false;
let startX, scrollLeft;

let startDragging = function (e) {
  mouseDown = true;
  startX = e.pageX - carouselCont.offsetLeft;
  scrollLeft = carouselCont.scrollLeft;
};
let stopDragging = function (event) {
  mouseDown = false;
};

carouselCont.addEventListener('mousemove', (e) => {
  e.preventDefault();
  if(!mouseDown) { return; }
  const x = e.pageX - carouselCont.offsetLeft;
  const scroll = x - startX;
  carouselCont.scrollLeft = scrollLeft - scroll;
});

// Add the event listeners
carouselCont.addEventListener('mousedown', startDragging, false);
carouselCont.addEventListener('mouseup', stopDragging, false);
carouselCont.addEventListener('mouseleave', stopDragging, false);
  // carouselCont.addEventListener( 'scroll', function () {
  //   document.querySelector('#img-0').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 20 + 'px)'
  //   document.querySelector('#img-1').style.transform = 'translateX(' + -1.5 * carouselCont.scrollLeft / 300 + 'px)'
  //   document.querySelector('#img-2').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 20 + 'px)'
  //   document.querySelector('#img-3').style.transform = 'translateX(' + 0.5 * carouselCont.scrollLeft / 50 + 'px)'
  //   document.querySelector('#img-4').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 100 + 'px)'
  //   document.querySelector('#img-5').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 70 + 'px)'
  //   document.querySelector('#img-6').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 50 + 'px)'
  //   document.querySelector('#img-7').style.transform = 'translateX(' + -1.5 * carouselCont.scrollLeft / 130 + 'px)'
  //   document.querySelector('#img-8').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 10 + 'px)'
  //   document.querySelector('#img-9').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 80 + 'px)'
  //   document.querySelector('#img-10').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 35 + 'px)'
  //   document.querySelector('#img-11').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 20 + 'px)'
  //   document.querySelector('#img-12').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 80 + 'px)'
  //   document.querySelector('#img-13').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 10 + 'px)'
  //   document.querySelector('#img-14').style.transform = 'translateX(' + -1.5 * carouselCont.scrollLeft / 20 + 'px)'
  //   document.querySelector('#img-15').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 200 + 'px)'
  //   document.querySelector('#img-16').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 50 + 'px)'
  //   document.querySelector('#img-17').style.transform = 'translateX(' + -1.5 * carouselCont.scrollLeft / 40 + 'px)'
  //   document.querySelector('#img-18').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 20 + 'px)'
  //   document.querySelector('#img-19').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 50 + 'px)'
  //   document.querySelector('#img-20').style.transform = 'translateX(' + -1 * carouselCont.scrollLeft / 100 + 'px)'
  // })
  }, [])

  return (
    <div id='tesr'>
      <div className='secCard'>
        <div id="cardsHeading2-cont">
          <div className='cardsHeading2'>Shh! Let’s show you what we got</div>
          <div className='cardsHeading2bg'>Sneakpeak</div>
        </div>
        <div className="cardsPara">
          We’re taking stylish digs to a whole other level. You now get total bragging rights, without breaking the bank.
        </div>
        <div className='ddContMain'>
          <div className="carouselDD" id='ddMm2' style={ { marginTop: '20px' } }>
            Find Your Home!
          </div>
          <div className="dropdownCont" id='ddd2'>
          <hr />
          <a style={{textDecoration: 'none'}} href='/sycamore'><div className='dropdownElem'>Sycamore Tree by Dwellingo, HSR Layout, Sector 1</div></a>
          <hr />
          <a href='/derive' style={{textDecoration: 'none'}}><div className='dropdownElem'>Dérive by Dwellingo, Indiranagar 100 ft road</div></a>
          <hr />
          <a href='/ombre' style={{textDecoration: 'none'}}><div className='dropdownElem dropdownElemLast'>Ombré by Dwellingo, HSR Layout, Sector 1</div></a>
          </div>
        </div>
      </div>
      <div className='main-carousel-cont'>
        <div className="mainCarousel">
          <div className='deskCard'>
            <div id="cardsHeading2-cont">
              <div className='deskHeading2'>Shh! Let’s show you what we got</div>
              <div className='deskHeading2bg'>Sneakpeak</div>
            </div>
            <div className="cardsPara">
              We’re taking stylish digs to a whole other level. You now get total bragging rights, without breaking the bank.
            </div>
            <div className='ddContMain'>
              <div className="carouselDD" id='ddMm' style={ { marginTop: '20px' } }>
                Find Your Home!
              </div>
              <div className="dropdownCont" id='ddd'>
              <hr />
              <a style={{textDecoration: 'none'}} href='/sycamore'><div className='dropdownElem'>Sycamore Tree by Dwellingo, HSR Layout, Sector 1</div></a>
              <hr />
              <a href='/derive' style={{textDecoration: 'none'}}><div className='dropdownElem'>Dérive by Dwellingo, Indiranagar 100 ft road</div></a>
              <hr />
              <a href='/ombre' style={{textDecoration: 'none'}}><div className='dropdownElem dropdownElemLast'>Ombré by Dwellingo, HSR Layout, Sector 1</div></a>
              </div>
            </div>
          </div>
          { cards.map( ( e, index ) => <img src={e} loading='lazy' alt="img" key={index} id={`img-${index}`} className='carouselImg' />)}
        </div>
      </div>
    </div>
  )
}
