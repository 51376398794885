import React from 'react'
import { Helmet } from "react-helmet"
import Call from '../../components/call/Call';

import Image404 from '../../imgs/404.png'

import './404.css'

const Error404Page = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error 404 | Dwellingo</title>
      </Helmet>
      <Call />
      <div className='mainCont'>
        <img src={ Image404 } alt="" onLoad={ () => { if ( document.getElementById( 'loadingAnimation' ) ) { document.getElementById( 'loadingAnimation' ).style.display = 'none' } } } />
        <a href="/">Back To Homepage</a>
      </div>
    </div>
  )
}

export default Error404Page

