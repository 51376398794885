import React from 'react'
import { Helmet } from "react-helmet"
import Call from '../../components/call/Call';
import Thankyou from '../../imgs/thank-you.jpg'

import './thank-you.css'

const ThanksPage = () => {
  return (
    <div>
      <Helmet
        title={ 'Thank you | Dwellingo' }
      />
      <Call />
      <div className='thankyouCont'>
        <img src={ Thankyou } alt="" onLoad={ () => { if ( document.getElementById( 'loadingAnimation' ) ) { document.getElementById( 'loadingAnimation' ).style.display = 'none' } } } />
          <h2>Thank you for filling out this, we will call you back within one business day</h2>
        <a href="/">Back To Homepage</a>
      </div>
    </div>
  )
}

export default ThanksPage

