import React from 'react'

import './styles.css'

const ImageTextBlock = (props) => {
  return (
    <div className={`imageTextBlock ${props.reverse ? 'textBlockImage' : null} ${props.bgcWhite ? 'bgcWhite' : null}`}>
      <div className="imgTextBlkImgCont">
        <img src={props.image} alt="" loading='lazy' />
      </div>
      <div className="imgTextBlkTextCont">
        <div className="imgTextBlkHeading">
          {props.heading}
        </div>
        <div className="imgTextBlkDesc">
          {props.desc}
        </div>
      </div>
    </div>
  )
}

export default ImageTextBlock

