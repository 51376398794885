import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import './App.css'
import Footer from "./components/Footer/footer";


import Index from './pages/index/index'
import PricavyPolicy from "./pages/privacy-policy/privacy-policy";
import TermsConditions from "./pages/terms-conditions/terms-conditions";
import Sycamore from './pages/sycamore/property'
import Uno from './pages/Uno/uno'
import Ombre from './pages/ombre/ombre'
import ThanksPage from "./pages/thank-you/thank-you";
import Error404Page from "./pages/404/404";
import Derive from "./pages/derive/derive";

function App () {
  return (
    <Router>
      <Switch>
    {/* <Route exact path="/privacy-policy">
          <PricavyPolicy />
          <Footer />
        </Route>
        <Route exact path="/terms-conditions">
          <TermsConditions />
          <Footer />
        </Route> */}
        <Route exact path="/sycamore">
          <Sycamore />
          <Footer />
        </Route>
        <Route exact path="/derive">
          <Derive />
          <Footer />
        </Route>
        <Route exact path="/uno">
          <Uno />
          <Footer />
        </Route>
        <Route exact path="/ombre">
          <Ombre />
          <Footer />
        </Route>
        <Route exact path="/">
          <Index />
          <Footer pathIndex />
        </Route>
        <Route exact path="/thank-you">
          <ThanksPage />
          <Footer />
        </Route>
        <Route path="/*">
          <Error404Page />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
