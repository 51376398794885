import React, { useState } from 'react'
import { render } from "react-dom";
import ContactForm from '../contact-form/contact-form';

import LogoSmall from '../../imgs/logoBig.png'

import './styles.css'

const Navbar = () => {
  const [form, setForm] = useState( false )
  
  React.useEffect(() => {
    document.getElementById('ddCont').style.width = document.getElementById('dd').offsetWidth + 'px'
  }, [])

  render(<ContactForm open={form} setOpen={setForm} />, document.getElementById("formModal"))

  return (
    <div className='navbarContainer'>
      <div className='navBasket'>
        <a href='/'><img src={LogoSmall} alt="logo" className='nav-logo' /></a>
        {/* <div className="navBtn" onClick={() => setForm(true)}>
          Hit Us Up
  </div> */}
        <div className='ddContMain'>
          <div className="carouselDD navBtn" id='dd'>
            Find Your Home!
          </div>
          <div className="dropdownCont" id='ddCont'>
          <hr />
          <a style={{textDecoration: 'none'}} href='/sycamore'><div className='dropdownElem'>Sycamore Tree by Dwellingo, HSR Layout, Sector 1</div></a>
          <hr />
          <a href='/derive' style={{textDecoration: 'none'}}><div className='dropdownElem'>Dérive by Dwellingo, Indiranagar 100 ft road</div></a>
          <hr />
          <a href='/ombre' style={{textDecoration: 'none'}}><div className='dropdownElem dropdownElemLast'>Ombré by Dwellingo, HSR Layout, Sector 1</div></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar

