import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'

import ChevronLeft from '../../imgs/chevron-left.png'
import ChevronRight from '../../imgs/chevron-right.png'
import testimonyImg1 from '../../imgs/testimony/1.jfif'
import testimonyImg2 from '../../imgs/testimony/2.png'
import testimonyImg3 from '../../imgs/testimony/3.jfif'

import './styles.css'

const Card = (props) => {
  return (
    <div className='cardCont'>
      <div className="quoteCont">
        <div className="quoteHeading">
          {props.heading}
        </div>
        <div className="quote">
          “{props.quote}“
        </div>
        <div className="quotePerson">
          {props.name}
        </div>
        <div className="quoteBio">
          {props.personBio}
        </div>
      </div>
      <img src={props.img} alt="" className='quoteImg' loading='lazy' />
    </div>
  )
}

const Testimonial = () => {
  const [Index, setIndex] = useState(0)

  return (
    <div className='testimonailContCont'>
      <div className="imgTextBlkHeading" style={{ textAlign: 'center', position: 'relative', zIndex: '10' }}>
        We take pride in our experiences
      </div>
      <div className='testimonialContainer'>
        <img src={ChevronLeft} alt='' onClick={() => setIndex(Index-1)} className='chevronBtns' />
        <div className="mobChevronCont">
        <img src={ChevronLeft} alt='' onClick={() => setIndex(Index-1)} className='chevronBtnsMob' />
        <img src={ChevronRight} alt='' onClick={() => setIndex(Index+1)} className='chevronBtnsMob' />
        </div>
        <Carousel
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          onChange={ setIndex }
          selectedItem={ Index }
          useKeyboardArrows
          style={{ maxWidth: '80vw' }}
        >
          <Card img={testimonyImg1} name='Rohan Arora' quote='What sets Dwellingo apart is their quality and reliability. Having lived in 3 other co-living properties in spice-garden, Marathalli, they are the only co-living property with clean, hygienic and always-happy staff.' />
          <Card img={testimonyImg2} name='Joji Zacharias' quote='I work at an animation studio in Whitefield and my job involves uploading terabytes of data a day. After Covid-19, Dwellingo was the only co-living solution I found that supported my WFH needs through uninterrupted wifi, download speeds and service.' />
          <Card img={testimonyImg3} name='Adarsh Singh' quote='The Dwellingo experience from the in-app social interactions that spill over into making real-world connections with other Dwellers, the book-club, standup nights and mindfulness classes helped keep me sane and miss home a lot less, during covid-19. Keep it up team Dwellingo!' />
        </Carousel>
        <img src={ChevronRight} alt='' onClick={() => setIndex(Index+1)} className='chevronBtns' />
      </div>
    </div>
  )
}

export default Testimonial
