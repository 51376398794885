import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"

import HeroSection from '../../components/hero-parallax/heroParallax'

import LastImgs1 from '../../imgs/index-last1.png'
import LastImgs2 from '../../imgs/index-last2.png'
import LastImgs3 from '../../imgs/index-last2.png'
import AfterHeroImg1 from '../../imgs/afterHero1.png'
import AfterHeroImg2 from '../../imgs/afterHero2.png'
import AfterHeroImg3 from '../../imgs/afterHero3.png'
import AfterHeroImg4 from '../../imgs/afterHero4.png'
import AfterHeroImg5 from '../../imgs/afterHero5.png'
import Blog0 from '../../imgs/prpoImgs/photo 1.jpg'
import Blog2 from '../../imgs/prpoImgs/photo 2.jpg'
import Blog14 from '../../imgs/prpoImgs/photo 14.jpg'
import Circle from '../../imgs/circle.png'
import Call from '../../components/call/Call';
import ReactDOM from 'react-dom';

import './index.css'
import CardDeck from '../../components/card-deck/cardDeck'
import Testimonial from '../../components/testimonial/testimonial'
import ImageTextBlock from '../../components/img-text-block/imageTextBlock'

const AfterHero = () => {
  return (
    <div className='afterHeroContainer'>
    <div className="afterHeroImg afterHeroImg1"><img src={AfterHeroImg1} alt="" style={{ width: '100%' }} loading='lazy' /></div>
    <div className="afterHeroImg afterHeroImg2"><img src={AfterHeroImg2} alt="" style={{ width: '100%' }} loading='lazy' /></div>
    <div className="afterHeroImg afterHeroImg3"><img src={AfterHeroImg3} alt="" style={{ width: '100%' }} loading='lazy' /></div>
    <div className="afterHeroImg afterHeroImg4"><img src={AfterHeroImg4} alt="" style={{ width: '100%' }} loading='lazy' /></div>
    <div className="afterHeroImg afterHeroImg5"><img src={AfterHeroImg5} alt="" style={{ width: '100%' }} loading='lazy' /></div>
      <div className="afterHeroHeading">
        <h1 className="afterHeroBgHeading">Dwellingo</h1>
        <h3 className="afterHeroMainHeading">Live life on your own terms</h3>
      </div>
      <div className="afterHeroPara1">
        A community with a singular purpose of <span className='greenColor'>fostering, fulfilling and meaningful relationships.</span>
      </div>
      <div className="afterHeroPara2">
        co-living, co-working and community <span className='greenColor'>all under one roof</span>
      </div>
      <div className="afterHeroPara3">
        Live life <span className='greenColor'>on your own terms.</span>
      </div>
    </div>
  )
}

const Index = () => {
  useEffect( () => {
    document.getElementById( 'loadingAnimation' ).style.display = 'flex'
    setTimeout(() => {if(document.getElementById('loadingAnimation')){document.getElementById('loadingAnimation').style.display='none'}}, 1000)
    document.getElementById('nav').style.display = 'block'
    document.addEventListener( 'scroll', function () {
      var circleBlob = document.querySelector( '#blogCircle' )
      var blogCont = document.querySelector( '.blogsCont' )
      var value = window.scrollY
      if(circleBlob && blogCont) {if ( blogCont.offsetTop + blogCont.offsetHeight/2 < value + window.innerHeight ) {
        circleBlob.style.transform = 'translate(-50%, -' + (value + window.innerHeight - blogCont.offsetTop)/2 +'px )'
      }}
    } )
  }, [] )

  ReactDOM.render(<Call />, document.getElementById("callC2a"))

  return (
    <div style={ { marginBottom: '30px' } }>
      <Helmet>
        <meta name="title" content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore." />
        <meta name="description"
          content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore with 24x7 deep cleaning, coworking areas, concierge services, community events and workshops. All inclusive rent. " />
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dwellingo.in/" />
        <meta property="og:title" content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore." />
        <meta property="og:description"
          content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore with 24x7 deep cleaning, coworking areas, concierge services, community events and workshops. All inclusive rent. " />
        <meta property="og:image"
          content="https://www.dwellingo.in/logo512.png" />
        
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.dwellingo.in/" />
        <meta property="twitter:title" content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore." />
        <meta property="twitter:description"
          content="Fully furnished luxury coliving studios and 1BHK apartments in Bangalore with 24x7 deep cleaning, coworking areas, concierge services, community events and workshops. All inclusive rent. " />
        <meta property="twitter:image"
          content="https://www.dwellingo.in/logo512.png" />
      </Helmet>
      <HeroSection />
      <AfterHero />
      <CardDeck />
      <ImageTextBlock image={LastImgs1} heading='Live life hassle free.' desc='Whether its your laundry at your doorstep, working from home with enterprise-grade wifi, or paying for it all with the click of a button, we gotchu fam!' />
      <Testimonial />
      <ImageTextBlock image={LastImgs2} reverse heading='Rediscover a hobby or find a new one' desc="From mixology masterclasses to guitar lessons, we'll make sure you go from noob to jedi in no time!" />
      <div className="blogsCont">
        <div className="imgTextBlkHeading" style={{ textAlign: 'center', position: 'relative', zIndex: '10' }}>
          Read some interesting stories
        </div>
        <img src={Circle} id='blogCircle' alt="" />
        <div className="blogCardCont">
          <a href='https://medium.com/@dwellingo/the-co-living-ecosystem-in-india-is-broken-heres-how-we-fix-it-a197436bb706' target='_' className="blogCard">
            <img src={Blog0} alt="" loading='lazy' />
            <div className="blogHeading">
              The Co-Living Ecosystem in India is Broken: Here’s How We Fix It
            </div>
            <div className="blogDesc">
              The year is 2020 and we are eight..
            </div>
          </a>
          <a href='https://medium.com/@dwellingo/dwellingo-our-why-a86331b30342' target='_' className="blogCard">
            <img src={Blog2} alt="" loading='lazy' />
            <div className="blogHeading">
              Dwellingo: Our Why
            </div>
            <div className="blogDesc">
              At Dwellingo, we pride ourselves on..
            </div>
          </a>
          <a href='https://medium.com/@dwellingo/curated-living-a-masterclass-1-4-dddd3a173caa' target='_' className="blogCard">
            <img src={Blog14} alt="" loading='lazy' />
            <div className="blogHeading">
              Curated Living: A Masterclass - 1/4
            </div>
            <div className="blogDesc">
              The year is 2020; Atmanirbhar Ramesh..
            </div>
          </a>
        </div>
      </div>
      <ImageTextBlock bgcWhite image={ LastImgs3 } heading='On a quest to eliminate urban loneliness' desc='You deserve so much more than a room and a loo. You deserve a tribe, a 3 AM buddy, your chosen family, a place that you can truly make your own. You deserve Dwellingo.' />
      <Helmet
        script={ [{
          type: 'text/javascript',
          innerHTML: `
             var _ua = ( function ( u ) {
      return {
        Tablet: ( u.indexOf( "windows" ) != -1 && u.indexOf( "touch" ) != -1 && u.indexOf( "tablet pc" ) == -1 )
          || u.indexOf( "ipad" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) == -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "tablet" ) != -1 )
          || u.indexOf( "kindle" ) != -1
          || u.indexOf( "silk" ) != -1
          || u.indexOf( "playbook" ) != -1,
        Mobile: ( u.indexOf( "windows" ) != -1 && u.indexOf( "phone" ) != -1 )
          || u.indexOf( "iphone" ) != -1
          || u.indexOf( "ipod" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || u.indexOf( "blackberry" ) != -1
      }
    } )( window.navigator.userAgent.toLowerCase() );

    var luxyEl = document.getElementById( 'root' );
    if ( luxyEl ) {
      if ( !_ua.Mobile && !_ua.Tablet ) {
        luxy.init( {
          wrapper: '#root',
          wrapperSpeed: 0.065,

        } );
      } else {
        luxyEl.style.position = 'unset'
      }
    }
          `
        }]}
      />
    </div>
  )
}

export default Index
