import React from 'react'
import ReactTypingEffect from 'react-typing-effect'

import HeroBg from '../../imgs/heroBG.png'
import Building from '../../imgs/heroBuilding.png'
import Wave from '../../imgs/wave.png'

import './styles.css'

const HeroParallax = () => {


  window.addEventListener( 'scroll', function () {
    let Bg = document.getElementById('heroBG')
    let BuildingFront = document.getElementById('BuildingFront')
    let TextSruff = document.getElementById( 'heroText' )
    let Container = document.querySelector( '.heroSecContainer' )
    var value = window.scrollY
    
    if(Container && Bg && BuildingFront && TextSruff) {
      Container.style.height = BuildingFront.offsetTop + BuildingFront.offsetHeight + 'px'
      Bg.style.top = '0px'
      TextSruff.style.top = 'calc(50vh + ' + value + 'px)'
      BuildingFront.style.top = 'calc(65vh - ' + value * 0.5 + 'px)'
      TextSruff.style.opacity = 1 - value * 2 / window.innerHeight
    }
  } )
  
  window.addEventListener( 'load', function () {
    let BuildingFront = document.getElementById('BuildingFront')
    let Container = document.querySelector( '.heroSecContainer' )
    
    Container.style.height = BuildingFront.offsetTop + BuildingFront.offsetHeight + 'px'
  })

  return (
    <div className='heroSecContainer'>
      <img src={HeroBg} alt="hero bg sky" className='heroBG' id='heroBG' />
      <img src={Building} alt="hero front building" className='BuildingFront' id='BuildingFront' />
      <div className='heroText' id='heroText'>
        <h1 className='heroName'>
          Say <span style={{ color: '#F2C94C' }}>Goodbye</span><img src={Wave} className='wave' alt="wave emoji apple" />  to <br /> <ReactTypingEffect
        text={["Shady Landlords", "Creepy Flatmates", "Insane Deposits", "Boring Workspaces"]} cursor=' ' speed={75} eraseDelay={3200} typingDelay={1000} eraseSpeed={75}
      />
        </h1>
        <h4 className='heroDesc'>
          Discover what it means to live, love, work and thrive under the same roof
        </h4>
      </div>
    </div>
  )
}

export default HeroParallax

